import localesMixin from '@/mixins/locales'
import { TimeframeMixin } from '@common/mixins'
import get from 'lodash/get'
import { useAssetStore } from '@/stores/assets'

import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/data.gql'

const assetDimensionsMixin = {
  mixins: [TimeframeMixin, localesMixin],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  data() {
    return {
      totals: {},
      variationAbsolutes: {},
      variationPercentages: {},
      aggrByAsset: {},
      aggrByTimebuckets: {},
      dataPoints: {},
      aggrByShiftStart: {},
    }
  },
  computed: {
    assetIds() {
      const assetsFromStore = this.assetStore.assetsSelected()
      return assetsFromStore.map(({ id }) => id)
    },
    allReady() {
      return this.$apollo.loading
    },
    dimensions() {
      const dims = this.widget?.dimensions ?? []
      return dims.sort((a, b) => {
        return a.position - b.position
      })
    },
    assetsMatched() {
      const ids = this.dimensions.reduce((acc, item) => {
        const label = get(item, 'assetDimension.name', 'unknown')
        get(this.aggrByAsset, label, []).forEach(item => {
          if (!acc.includes(item.id)) {
            acc.push(item.id)
          }
        })
        return acc
      }, [])
      return ids.length
    },
  },
  watch: {
    dimensions: {
      handler(dimensions) {
        dimensions.forEach(dimension => {
          const name = dimension?.assetDimension?.name ?? null
          if (name) {
            this.$apollo.addSmartQuery(name, {
              query: ASSET_DIMENSION_DATA_QUERY,
              variables() {
                return {
                  where: {
                    assetDimension: {
                      name,
                    },
                    timeframe: this.selectedTimeframeParam,
                    assets: {
                      id_in: this.assetIds,
                    },
                  },
                }
              },
              skip() {
                return dimensions.length < 1 || this.assetIds.length < 1 || !this.selectedTimeframe
              },
              result(res) {
                const total = res?.data?.assetDimensionData?.total ?? null
                const variationAbsolutes = res?.data?.assetDimensionData?.variation?.absolute ?? null
                const variationPercentages = res?.data?.assetDimensionData?.variation?.percent ?? null
                const aggrByAsset = res?.data?.assetDimensionData?.aggrByAsset ?? []
                const aggrByTimebuckets = res?.data?.assetDimensionData?.aggrByTimebuckets ?? []
                const dataPoints = res?.data?.assetDimensionData?.dataPoints ?? []
                const aggrByShiftStart = res?.data?.assetDimensionData?.aggrByShiftStart ?? []

                this.$set(this.totals, name, total)
                this.$set(this.variationAbsolutes, name, variationAbsolutes)
                this.$set(this.variationPercentages, name, variationPercentages)
                this.$set(this.aggrByAsset, name, aggrByAsset)
                this.$set(this.aggrByTimebuckets, name, aggrByTimebuckets)
                this.$set(this.aggrByShiftStart, name, aggrByShiftStart)
                this.$set(this.dataPoints, name, dataPoints)
              },
              manual: true,
            })
          }
        })
      },
      immediate: true,
    },
  },
}

export default assetDimensionsMixin
