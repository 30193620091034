<template>
  <AssetDimensionMultiDimensionWidget v-if="widget" :widget="widget" />
</template>

<script>
import AssetDimensionMultiDimensionWidget from '@/components/Atomic/Organisms/Dashboard/AssetDimensionMultiDimensionWidget'

export default {
  props: {
    widget: {
      type: Object,
      required: false,
    },
  },
  components: {
    AssetDimensionMultiDimensionWidget,
  },
}
</script>
