<template>
  <VerticalBarsChartStyled :hasNoData="!showChart">
    <BarChart :option="option" v-if="showChart" />
    <NoDataStyled v-else>{{ message }}</NoDataStyled>
  </VerticalBarsChartStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'
import BarChart from '@/components/Atomic/Atoms/SimpleBarChart'
import localesMixin from '@/mixins/locales'
import { TimeframeMixin } from '@common/mixins'
import { DateTime } from 'luxon'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

const VerticalBarsChartStyled = styled('div')`
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${({ hasNoData }) => hasNoData && `display:flex; justify-content:center;align-items: center;`};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`
export default {
  inject: ['theme', 'uiSettings'],
  mixins: [localesMixin, TimeframeMixin],
  props: {
    dimensionMapped: {
      type: Object,
      required: true,
    },
    aggrByTimebuckets: {
      type: Object,
      required: true,
    },
  },
  components: {
    VerticalBarsChartStyled,
    BarChart,
    NoDataStyled,
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    evolutionOverTimeLabels() {
      let labels = []
      labels = this.timeBucketsUI
      return labels
    },
    evolutionOverTimeDatasets() {
      const label = this.dimensionMapped?.assetDimension?.name
      const unitSI = this.dimensionMapped?.assetDimension?.physicalUnitSI ?? ''
      const unitUIMetric = this.dimensionMapped?.assetDimension?.physicalUnitUIMetric ?? ''
      const unitUIImperial = this.dimensionMapped?.assetDimension?.physicalUnitUIImperial ?? null
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      const unit = unitUI ? unitUI.replace('mt', 't') : unitSI
      const mappedLabel =
        getAssetDimensionNameByLocale(this.dimensionMapped?.assetDimension?.nameTranslations, this.locale) ?? 'unknown title'

      const data = this.timeBuckets.map(timeBucket => {
        const found = this.aggrByTimebuckets?.[label]?.find(f => {
          const time = DateTime.fromISO(f.time)
          return timeBucket.contains(time)
        })
        return {
          value: found !== undefined ? this.convUnit(found.floatValue, unitSI, unitUI, 2, false) : null,
          unit: unit ? unit : '',
        }
      })

      return {
        label: mappedLabel,
        data,
        backgroundColor: this.theme.colors.charts.first,
        borderColor: this.theme.colors.charts.first,
        maxBarThickness: 30,
      }
    },
    option() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            let tooltip = `${params[0].name}`
            const paramFiltered = params.filter(item => item.value !== undefined)
            paramFiltered.forEach(param => {
              tooltip += `<br/> ${param.marker} ${param.seriesName}
                        <B style="float:right;margin-left:1rem">
                          ${param.data?.value ?? '-'}<span style="margin-left:0.5rem">${param.data?.unit}<span>
                        </B>`
            })
            return tooltip
          },
        },
        grid: {
          left: '25',
          right: '15',
          bottom: '15',
          top: '15',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: this.evolutionOverTimeLabels,
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            nameLocation: 'middle',
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
            },
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
              formatter: params => {
                return this.numberLocalized(params)
              },
            },
            splitLine: {
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
            nameGap: '20',
          },
        ],
        series: [
          {
            name: getAssetDimensionNameByLocale(this.dimensionMapped?.assetDimension?.nameTranslations, this.locale) ?? 'unknown title',
            data: this.evolutionOverTimeDatasets?.data,
            type: 'bar',
            barMaxWidth: 30,
          },
        ],
      }
    },
    showChart() {
      return this.evolutionOverTimeDatasets?.data?.filter(f => f.value !== null)?.length > 0 ?? false
    },
    message() {
      const dimensionNames = this.option?.series[0]?.name
      return this.$t('messages.noDimensionData', { dimension: dimensionNames })
    },
  },
}
</script>
