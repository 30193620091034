<template>
  <TalpaLoaderWrapper class="talpa-loader-wrapper" v-if="isLoading" />
  <MultiDimensionWidgetStyled v-else :class="{ 'with-title': hasTitleSlot }">
    <template>
      <WidgetHeaderMolecule :title="$t('widgetTypes.MULTI_DIMENSION')" :assetDimensions="[]" />

      <MultiDimensionHeaders :headerTiles="headerTiles" @selectHeader="$emit('selectHeader', $event)">
        <slot name="header" />
      </MultiDimensionHeaders>
      <MultiKPICardTitleStyled>
        <slot name="title" />
      </MultiKPICardTitleStyled>
      <MultiKPICardMainStyled>
        <slot name="main" />
      </MultiKPICardMainStyled>
      <MultiKPICardFooterStyled>
        <slot name="footer" />
      </MultiKPICardFooterStyled>
    </template>
  </MultiDimensionWidgetStyled>
</template>
<script>
import { styled } from '@egoist/vue-emotion'

import { flexCenter } from '@styles/mixins'
import { TalpaLoaderWrapper } from '@common/components'

import MultiDimensionHeaders from './MultiDimensionHeaders'
import WidgetHeaderMolecule from '@/components/Atomic/Molecules/WidgetHeaderMolecule'

const MultiDimensionWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 2.5rem 5rem 1fr 3rem;
  grid-template-columns: 1fr;
  grid-template-areas:
    'widgetHeader'
    'header'
    'main'
    'footer';

  &.with-title {
    grid-template-rows: 2.5rem 5.6rem 3rem 1fr 3rem;
    grid-template-areas:
      'widgetHeader'
      'header'
      'title'
      'main'
      'footer';
  }

  .loading {
    ${flexCenter}
  }
`

const MultiKPICardMainStyled = styled('div')`
  grid-area: main;
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;

  .horizontal-enter-active,
  .horizontal-leave-active {
    transition: transform 0.5s;
    width: 100%;
  }
  .horizontal-enter,
  .horizontal-leave-to {
    transform: translateX(-100%);
  }

  .table-details.horizontal-enter,
  .table-details.horizontal-leave-to {
    transform: translateX(100%);
  }

  .horizontal-leave-active {
    position: absolute;
  }
`
const MultiKPICardTitleStyled = styled('div')`
  ${flexCenter}
  grid-area: title;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  height: 2rem;
  h4 {
    margin: 0;
    padding: 0;
  }
`

const MultiKPICardFooterStyled = styled('div')`
  ${flexCenter}
  grid-area: footer;
  height: 3rem;
`

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    headerTiles: {
      type: Array,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  components: {
    MultiDimensionWidgetStyled,
    MultiDimensionHeaders,
    MultiKPICardMainStyled,
    MultiKPICardTitleStyled,
    MultiKPICardFooterStyled,
    TalpaLoaderWrapper,
    WidgetHeaderMolecule,
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots['title']
    },
  },
}
</script>
