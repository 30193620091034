<template>
  <AssetDimensionMultiDimensionWidgetStyled>
    <MultiDimensionWidget
      :headerTiles="headerTiles"
      :isLoading="isLoading"
      @selectHeader="selectAssetDimension"
      :count="assetsSelected.length"
    >
      <template v-slot:header />
      <template v-slot:title>
        <h5>{{ selectedWidget.title }}</h5>
      </template>
      <template v-slot:main>
        <keep-alive>
          <VerticalBarsChart
            v-if="selectedWidgetID === 'EvolutionOverTime'"
            :dimensionMapped="dimensionMapped"
            :aggrByTimebuckets="aggrByTimebuckets"
          />
          <HorizontalBarsChart
            v-else-if="selectedWidgetID === 'MachineComparison'"
            :dimensionMapped="dimensionMapped"
            :assetsSelected="assetsSelected"
            :aggrByAsset="aggrByAsset"
            :selectedDimension="selectedDimension"
          />
        </keep-alive>
      </template>
      <template v-slot:footer>
        <WidgetSelectorStyled
          v-for="widget in availableWidgetsMapped"
          :key="widget.id"
          :class="{ selected: widget.isSelected }"
          @click="setSelectedWidget(widget)"
        >
          <CircleIcon />
        </WidgetSelectorStyled>
      </template>
    </MultiDimensionWidget>
  </AssetDimensionMultiDimensionWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { buttonReset } from '@styles/mixins'
import { CircleIcon } from 'vue-feather-icons'
import units from '@/utils/units'
import VerticalBarsChart from '@/components/Atomic/Molecules/MultiDimension/Charts/VerticalBarsChart'
import HorizontalBarsChart from '@/components/Atomic/Molecules/MultiDimension/Charts/HorizontalBarsChart'
import assetDimensionsMixin from '@/mixins/assetDimensions'
import MultiDimensionWidget from '@/components/Atomic/Molecules/MultiDimension/MultiDimensionWidget'
import { getAssetDimensionNameByLocale } from '@common/utils/src'
import { useAssetStore } from '@/stores/assets'

const availableWidgets = [
  {
    id: 'EvolutionOverTime',
    title: 'Evolution over time',
  },
  {
    id: 'MachineComparison',
    title: 'Machine comparison',
  },
]
const AssetDimensionMultiDimensionWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
`
const WidgetSelectorStyled = styled('button')`
  ${buttonReset}
  border: none;
  .feather {
    stroke: ${p => p.theme.colors.primary};
    fill: none;
  }
  &.selected {
    .feather {
      stroke: ${p => p.theme.colors.primary};
      fill: ${p => p.theme.colors.primary};
    }
  }
`
export default {
  mixins: [assetDimensionsMixin],
  inject: ['theme', 'uiSettings'],
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    AssetDimensionMultiDimensionWidgetStyled,
    WidgetSelectorStyled,
    MultiDimensionWidget,
    VerticalBarsChart,
    HorizontalBarsChart,
    CircleIcon,
  },
  data() {
    return {
      selectedDimension: '',
      availableWidgets: availableWidgets,
      selectedWidgetID: availableWidgets[0].id,
    }
  },
  watch: {
    selectedDimension: {
      handler() {
        if (!this.selectedDimension) {
          this.selectedDimension = this.dimensions.length > 0 ? this.dimensions[0].assetDimension?.name : null
        }
      },
      immediate: true,
    },
  },
  computed: {
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    dimensions() {
      const dimensions = this.widget?.dimensions ?? []
      return dimensions.sort((a, b) => {
        return a.position - b.position
      })
    },
    isLoading() {
      return this.$apollo.loading
    },
    dimensionMapped() {
      return this.dimensions?.filter(d => d?.assetDimension?.name === this.selectedDimension)[0]
    },
    availableWidgetsMapped() {
      return this.availableWidgets.map(w => ({
        ...w,
        title: w.id === 'EvolutionOverTime' ? this.$t('multiDimension.time') : this.$t('multiDimension.comparison'),
        isSelected: this.selectedWidgetID === w.id,
      }))
    },
    headerTiles() {
      return this.dimensions.map(dimension => {
        const assetDimensionName = dimension.assetDimension.name
        const unitSI = dimension?.assetDimension?.physicalUnitSI ?? null
        const unitUIMetric = dimension?.assetDimension?.physicalUnitUIMetric ?? ''
        const unitUIImperial = dimension?.assetDimension?.physicalUnitUIImperial ?? null
        const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
        const title = dimension?.assetDimension?.nameTranslations
          ? getAssetDimensionNameByLocale(dimension?.assetDimension?.nameTranslations, this.locale)
          : assetDimensionName
        let unit = unitUI
        if (unit) {
          unit = unit.replace('mt', 't')
        } else {
          unit = unitSI
        }
        const color = '#eee'
        const data = this.totals[assetDimensionName] ?? null
        const total =
          data !== null ? units(data, unitSI, unitUI, 2, false, false, false, this.thousandsSeperator, this.decimalSeperator, true) : '-'
        return {
          id: assetDimensionName,
          name: assetDimensionName,
          total,
          unit,
          color,
          isOverview: false,
          title,
          isSelected: assetDimensionName === this.selectedDimension,
          showTrendAbsolute: false,
          showTrendPercent: false,
        }
      })
    },
    selectedWidget() {
      return this.availableWidgetsMapped.find(w => w?.isSelected)
    },
  },
  methods: {
    selectAssetDimension(id) {
      this.selectedDimension = id
    },
    setSelectedWidget(widget) {
      this.selectedWidgetID = widget?.id
    },
  },
}
</script>
