var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isLoading ? _c('TalpaLoaderWrapper', {
    staticClass: "talpa-loader-wrapper"
  }) : _c('MultiDimensionWidgetStyled', {
    class: {
      'with-title': _vm.hasTitleSlot
    }
  }, [[_c('WidgetHeaderMolecule', {
    attrs: {
      "title": _vm.$t('widgetTypes.MULTI_DIMENSION'),
      "assetDimensions": []
    }
  }), _c('MultiDimensionHeaders', {
    attrs: {
      "headerTiles": _vm.headerTiles
    },
    on: {
      "selectHeader": function selectHeader($event) {
        return _vm.$emit('selectHeader', $event);
      }
    }
  }, [_vm._t("header")], 2), _c('MultiKPICardTitleStyled', [_vm._t("title")], 2), _c('MultiKPICardMainStyled', [_vm._t("main")], 2), _c('MultiKPICardFooterStyled', [_vm._t("footer")], 2)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }