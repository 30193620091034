<template>
  <VerticalBarsChartStyled :labelCount="labelCount" :hasNoData="!showChart">
    <BarChart :option="option" v-if="showChart" />
    <NoDataStyled v-else>{{ message }}</NoDataStyled>
  </VerticalBarsChartStyled>
</template>
<script>
import { chain, flatten, orderBy } from 'lodash'
import { styled } from '@egoist/vue-emotion'
import chroma from 'chroma-js'
import units from '@/utils/units'
import BarChart from '@/components/Atomic/Atoms/SimpleBarChart'
import localesMixin from '@/mixins/locales'
import { TimeframeMixin } from '@common/mixins'
import { getAssetDimensionNameByLocale } from '@common/utils/src'

const VerticalBarsChartStyled = styled('div')`
  width: 100%;
  height: ${p => (!p.hasNoData ? (p.labelCount >= 15 ? p.labelCount * 20 + 'px' : '100%') : '100%')};
  overflow-x: hidden;
  overflow-y: auto;
  overflow: hidden;
  min-height: ${p => (p.labelCount >= 10 ? '200px' : '100%')};
  ${({ hasNoData }) => hasNoData && `display:flex; justify-content:center;align-items: center;`};
`
const NoDataStyled = styled('div')`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.solidBG};
  border-radius: 0.5rem;
`
export default {
  inject: ['theme', 'uiSettings'],
  mixins: [localesMixin, TimeframeMixin],
  props: {
    dimensionMapped: {
      type: Object,
      required: true,
    },
    assetsSelected: {
      type: Array,
      required: true,
    },
    aggrByAsset: {
      type: Object,
      required: true,
    },
    selectedDimension: {
      type: String,
      required: true,
    },
  },
  components: {
    VerticalBarsChartStyled,
    BarChart,
    NoDataStyled,
  },
  computed: {
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    labelCount() {
      return this.machineComparisonLabels.length
    },
    colors() {
      const colors = this.theme.isDark
        ? [this.theme.colors.charts.first, this.theme.colors.charts.third, this.theme.colors.charts.second]
        : [this.theme.colors.charts.first, this.theme.colors.darkGrey, this.theme.colors.charts.third]
      return chroma.scale(colors).colors(this.groupedAssets.length)
    },
    groupedAssets() {
      const mappedAssetData = this.assetsSelected.map(asset => ({
        ...asset,
        value: this.aggrByAsset[this.selectedDimension].find(fa => fa.assetId === asset.id)?.floatValue ?? null,
      }))
      const nonZero = mappedAssetData.filter(a => a.value !== null)

      const sortedAssets = chain(nonZero)
        .groupBy('type.id')
        .map(item => {
          return orderBy(item, 'name')
        })
        .value()
      const zeroArray = mappedAssetData.filter(a => a.value === null)
      return flatten(sortedAssets).concat(zeroArray)
    },
    machineComparisonDatasets() {
      const unitSI = this.dimensionMapped?.assetDimension?.physicalUnitSI ?? null
      const unitUIMetric = this.dimensionMapped?.assetDimension?.physicalUnitUIMetric ?? null
      const unitUIImperial = this.dimensionMapped?.assetDimension?.physicalUnitUIImperial ?? null
      const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
      const unit = unitUI ? unitUI.replace('mt', 't') : unitSI
      const data = this.groupedAssets.map(asset => {
        return {
          value:
            asset.value !== null
              ? units(asset.value, unitSI, unitUI, 2, false, false, false, this.thousandsSeperator, this.decimalSeperator, true)
              : '-',
          unit: unit ? unit : '',
        }
      })

      return {
        label: this.selectedDimension || 'unknown title',
        data,
        maxBarThickness: 30,
        tooltipUnit: unitUI,
      }
    },
    machineComparisonLabels() {
      return this.groupedAssets.map(asset => asset.name)
    },
    option() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function (params) {
            let tooltip = `${params[0].name}`
            const paramFiltered = params.filter(item => item.value !== undefined)
            paramFiltered.forEach(param => {
              tooltip += `<br/> ${param.marker} ${param.seriesName}
                        <B style="float:right;margin-left:1rem">
                          ${param.data?.value ?? '-'}<span style="margin-left:0.5rem">${param.data?.unit}<span>
                        </B>`
            })
            return tooltip
          },
        },
        grid: {
          left: '25',
          right: '15',
          bottom: '15',
          top: '15',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'value',
            nameLocation: 'middle',
            nameTextStyle: {
              color: this.theme.colors.textActivePrimary,
            },
            axisLabel: {
              color: this.theme.colors.textActivePrimary,
              formatter: params => {
                return this.numberLocalized(params)
              },
            },
            axisLine: {
              show: true,
            },
            splitLine: {
              lineStyle: {
                color: this.theme.colors.atomic.mildgrey,
              },
            },
            nameGap: '20',
          },
        ],
        yAxis: [
          {
            type: 'category',
            data: this.machineComparisonLabels,
            inverse: true,
            axisLabel: {
              interval: 0,
              color: this.theme.colors.textActivePrimary,
            },
            axisTick: {
              show: true,
              alignWithLabel: true,
            },
          },
        ],
        series: [
          {
            name: getAssetDimensionNameByLocale(this.dimensionMapped?.assetDimension?.nameTranslations, this.locale) ?? 'unknown title',
            data: this.machineComparisonDatasets?.data,
            type: 'bar',
            barMaxWidth: 30,
          },
        ],
      }
    },
    showChart() {
      return this.machineComparisonDatasets?.data?.filter(f => f.value !== '-')?.length > 0 ?? false
    },
    message() {
      const dimensionName = this.option?.series[0]?.name
      return this.$t('messages.noDimensionData', { dimension: dimensionName })
    },
  },
}
</script>
