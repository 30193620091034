var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('AssetDimensionMultiDimensionWidgetStyled', [_c('MultiDimensionWidget', {
    attrs: {
      "headerTiles": _vm.headerTiles,
      "isLoading": _vm.isLoading,
      "count": _vm.assetsSelected.length
    },
    on: {
      "selectHeader": _vm.selectAssetDimension
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        return [_c('h5', [_vm._v(_vm._s(_vm.selectedWidget.title))])];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('keep-alive', [_vm.selectedWidgetID === 'EvolutionOverTime' ? _c('VerticalBarsChart', {
          attrs: {
            "dimensionMapped": _vm.dimensionMapped,
            "aggrByTimebuckets": _vm.aggrByTimebuckets
          }
        }) : _vm.selectedWidgetID === 'MachineComparison' ? _c('HorizontalBarsChart', {
          attrs: {
            "dimensionMapped": _vm.dimensionMapped,
            "assetsSelected": _vm.assetsSelected,
            "aggrByAsset": _vm.aggrByAsset,
            "selectedDimension": _vm.selectedDimension
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return _vm._l(_vm.availableWidgetsMapped, function (widget) {
          return _c('WidgetSelectorStyled', {
            key: widget.id,
            class: {
              selected: widget.isSelected
            },
            on: {
              "click": function click($event) {
                return _vm.setSelectedWidget(widget);
              }
            }
          }, [_c('CircleIcon')], 1);
        });
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }