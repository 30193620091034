var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MultiDimensionHeadersStyled', {
    attrs: {
      "columnsCount": _vm.headerTiles.length
    }
  }, _vm._l(_vm.headerTiles, function (card) {
    return _c('MultiDimensionHeader', {
      key: card.id,
      attrs: {
        "name": card.title,
        "isSelected": card.isSelected,
        "isOverview": card.isOverview,
        "smallTitle": card.smallTitle,
        "value": card.total,
        "unit": card.unit,
        "showUnit": card.showUnit,
        "showIcon": !!card.icon,
        "iconClass": card.icon,
        "trendRed": card.trendRed,
        "showTrendAbsolute": card.showTrendAbsolute,
        "trendAbsolute": card.trendAbsolute,
        "showTrendPercent": card.showTrendPercent,
        "trendPercent": card.trendPercent
      },
      on: {
        "select": function select($event) {
          return _vm.$emit('selectHeader', card.id);
        }
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }